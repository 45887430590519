// eslint-disable-next-line no-unused-vars
import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CommonErrorWindow from "../components/popup_windows/common_error_window/CommonErrorWindow";

const DEFAULT_COLOR = "#0059ff";
const DEFAULT_SIZE = 15;
const DEFAULT_INTENSIVE = 2;

const DEFAULT_CLASSIC_BUTTON_OPTIONS = {
  count: 1,
  text1: "More info",
  text2: "Gift",
  colorTxt1: "#000000",
  colorTxt2: "#000000",
  colorBtn1: "#007bff",
  colorBtn2: "#28a745",
  fontSize: 14,
  url1: "",
  url2: "",
  displayCondition: 1,
};

const DEFAULT_SYNTHETIC = {
  name: "",
  left: "",
  right: "",
  bottom: "",
  avatarId: 0,
  languageId: 0,
  voiceId: 0,
  text: "",
};

const DEFAULT_PAPYRUS_OPTIONS = {
  text: "I can speak!",
  textColor: "#000000",
  backgroundColor: "#D2B48C",
  width: 230,
  height: 60,
  fontSize: 20,
};

const DEFAULT_ADVANCED_BUTTON_OPTIONS = {
  gradient: {
    enabled: true,
    type: "linear",
    direction: "to right",
    color1: "#43cea2",
    color2: "#185a9d",
  },
  textColor: "#ffffff",
  buttons: [
    {
      text: "Price",
      action: "https://synthetics.live/pricing",
      style: {
        backgroundColor: "#43cea2",
        width: "130px",
        height: "30px",
        padding: "3px 10px",
        borderRadius: "20px",
        border: "none",
        fontSize: "16px",
        marginLeft: "20px",
      },
    },
    {
      text: "Contact Us",
      action: "https://synthetics.live/contactUs",
      style: {
        backgroundColor: "#ff7e5f",
        width: "130px",
        height: "30px",
        padding: "3px 10px",
        borderRadius: "20px",
        border: "none",
        fontSize: "16px",
        marginLeft: "0px",
      },
    },
    {
      text: "Info",
      action: "https://synthetics.live/partners",
      style: {
        backgroundColor: "#43cea2",
        width: "130px",
        height: "30px",
        padding: "3px 10px",
        borderRadius: "20px",
        border: "none",
        fontSize: "16px",
        marginLeft: "20px",
      },
    },
  ],
  animation: {
    backgroundSize: "400% 400%",
    transition: "background-position 1s ease",
    animation: "gradient-flow 5s ease infinite",
  },
};

const DefaultSettingsContext = createContext();

export const DefaultSettingsProvider = ({ children }) => {
  const [isErrorWindowOpen, setIsErrorWindowOpen] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const openErrorWindow = (text, redirect = false) => {
    setErrorText(text);
    setRedirectToLogin(redirect);
    setIsErrorWindowOpen(true);
  };

  const closeErrorWindow = () => {
    setIsErrorWindowOpen(false);
    setErrorText("");
    setRedirectToLogin(false);
  };

  const defaultSettings = useMemo(
    () => ({
      pulsarColor: DEFAULT_COLOR,
      pulsarSize: DEFAULT_SIZE,
      pulsarIntensive: DEFAULT_INTENSIVE,
      classicButtonOptions: DEFAULT_CLASSIC_BUTTON_OPTIONS,
      syntheticDefaults: DEFAULT_SYNTHETIC,
      papyrusOptions: DEFAULT_PAPYRUS_OPTIONS,
      advancedButtonOptions: DEFAULT_ADVANCED_BUTTON_OPTIONS,
      openErrorWindow,
      closeErrorWindow,
    }),
    []
  );

  return (
    <DefaultSettingsContext.Provider value={defaultSettings}>
      {children}
      <CommonErrorWindow
        open={isErrorWindowOpen}
        onClose={closeErrorWindow}
        text={errorText}
        redirectToLogin={redirectToLogin}
      />
    </DefaultSettingsContext.Provider>
  );
};

DefaultSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDefaultSettings = () => useContext(DefaultSettingsContext);
