// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./advancedButtonSettings.module.css";

const AdvancedButtonSettings = ({
  selectedAvatar,
  advancedButtonOptions,
  onButtonSettingsChange,
  onEnableChange,
  isEnabled,
  avatarPosition,
}) => {
  const [buttonEnabled, setButtonEnabled] = useState(isEnabled);

  const [tempValues, setTempValues] = useState(
    advancedButtonOptions.buttons.map((button) => ({
      text: button.text,
      action: button.action,
    }))
  );

  useEffect(() => {
    setButtonEnabled(isEnabled);
  }, [isEnabled]);

  const handleTempValueChange = (index, field, value) => {
    const updatedTempValues = [...tempValues];
    updatedTempValues[index] = { ...updatedTempValues[index], [field]: value };
    setTempValues(updatedTempValues);
  };

  const handleButtonOptionChange = (index, field) => {
    const updatedButtons = [...advancedButtonOptions.buttons];
    updatedButtons[index] = {
      ...updatedButtons[index],
      [field]: tempValues[index][field],
    };
    onButtonSettingsChange({ ...advancedButtonOptions, buttons: updatedButtons });
  };

  const handleGlobalOptionChange = (field, value) => {
    if (field === "width") {
      const updatedButtons = advancedButtonOptions.buttons.map((button) => ({
        ...button,
        style: {
          ...button.style,
          width: value,
        },
      }));
      onButtonSettingsChange({ ...advancedButtonOptions, buttons: updatedButtons });
    } else {
      onButtonSettingsChange({ ...advancedButtonOptions, [field]: value });
    }
  };

  const buttonStyles = advancedButtonOptions.buttons.map((buttonConfig) => {
    const gradient = advancedButtonOptions.gradient.enabled
      ? `${advancedButtonOptions.gradient.type}-gradient(${advancedButtonOptions.gradient.direction}, ${advancedButtonOptions.gradient.color1}, ${advancedButtonOptions.gradient.color2})`
      : null;

    return {
      backgroundImage: gradient,
      backgroundColor: !gradient ? buttonConfig.style?.backgroundColor || "#ffffff" : undefined,
      color: advancedButtonOptions.textColor,
      padding: buttonConfig.style?.padding || "10px",
      borderRadius: buttonConfig.style?.borderRadius || "5px",
      border: buttonConfig.style?.border || "none",
      fontSize: buttonConfig.style?.fontSize || "14px",
      width: buttonConfig.style?.width || "130px",
      height: buttonConfig.style?.height || "30px",
      marginLeft: buttonConfig.style?.marginLeft || "0",
      backgroundSize: advancedButtonOptions.animation?.backgroundSize || "100% 100%",
      transition: advancedButtonOptions.animation?.transition || "none",
      animation: advancedButtonOptions.gradient.enabled ? advancedButtonOptions.animation?.animation : undefined,
    };
  });

  useEffect(() => {
    onEnableChange(buttonEnabled);
  }, [buttonEnabled, onEnableChange]);

  return (
    <div className={styles.buttonSettingsContainer}>
      <div className={styles.leftSettings}>
        <h3>
          <u>Advanced button options</u>
        </h3>

        <div className={styles.settingsRow}>
          <label className={styles.labelWithInput}>
            <input type="checkbox" checked={!!buttonEnabled} onChange={() => setButtonEnabled(!buttonEnabled)} />
            Enable
          </label>
        </div>

        {buttonEnabled && (
          <>
            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Gradient type:
                <select
                  value={advancedButtonOptions.gradient.type}
                  onChange={(e) =>
                    handleGlobalOptionChange("gradient", {
                      ...advancedButtonOptions.gradient,
                      type: e.target.value,
                    })
                  }
                >
                  <option value="linear">Linear</option>
                </select>
              </label>
              <label className={styles.labelWithInput}>
                Gradient color 1:
                <input
                  type="color"
                  value={advancedButtonOptions.gradient.color1}
                  onChange={(e) =>
                    handleGlobalOptionChange("gradient", {
                      ...advancedButtonOptions.gradient,
                      color1: e.target.value,
                    })
                  }
                />
              </label>
              <label className={styles.labelWithInput}>
                Gradient color 2:
                <input
                  type="color"
                  value={advancedButtonOptions.gradient.color2}
                  onChange={(e) =>
                    handleGlobalOptionChange("gradient", {
                      ...advancedButtonOptions.gradient,
                      color2: e.target.value,
                    })
                  }
                />
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Text Color:
                <input
                  type="color"
                  value={advancedButtonOptions.textColor}
                  onChange={(e) => handleGlobalOptionChange("textColor", e.target.value)}
                />
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Button Width:
                <input
                  type="number"
                  min="50"
                  max="500"
                  value={
                    advancedButtonOptions.buttons[0]?.style?.width
                      ? advancedButtonOptions.buttons[0].style.width.replace("px", "")
                      : "130"
                  }
                  onChange={(e) => handleGlobalOptionChange("width", `${e.target.value}px`)}
                />
              </label>
            </div>

            {advancedButtonOptions.buttons.map((button, index) => (
              <div key={button.text + button.action}>
                <h4>Button {index + 1}</h4>
                <div className={styles.settingsRow}>
                  <label className={styles.labelWithInput}>
                    Text:
                    <input
                      type="text"
                      value={tempValues[index]?.text || ""}
                      onChange={(e) => handleTempValueChange(index, "text", e.target.value)}
                      onBlur={() => handleButtonOptionChange(index, "text")}
                    />
                  </label>
                </div>
                <div className={styles.settingsRow}>
                  <label className={styles.labelWithInput}>
                    URL:
                    <input
                      type="url"
                      value={tempValues[index]?.action || ""}
                      onChange={(e) => handleTempValueChange(index, "action", e.target.value)}
                      onBlur={() => handleButtonOptionChange(index, "action")}
                    />
                  </label>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      {buttonEnabled && (
        <div className={styles.rightAvatarSimulator}>
          <div className={`${styles.buttonsContainer} ${avatarPosition === "left" ? styles.left : styles.right}`}>
            {advancedButtonOptions.buttons.map((buttonConfig, index) => (
              <button
                key={buttonConfig.text + buttonConfig.action}
                className={`${styles.customButton} button-${index + 1}`}
                type="button"
                style={buttonStyles[index]}
                onClick={() => window.open(buttonConfig.action, "_blank")}
              >
                {buttonConfig.text}
              </button>
            ))}
          </div>
          <div className={styles.simulatorContainer}>
            <img src={selectedAvatar} alt="Avatar" className={styles.simulatorAvatar} />
          </div>
        </div>
      )}
    </div>
  );
};

AdvancedButtonSettings.defaultProps = {
  avatarPosition: "right",
  isEnabled: false,
};

AdvancedButtonSettings.propTypes = {
  selectedAvatar: PropTypes.string.isRequired,
  advancedButtonOptions: PropTypes.shape({
    gradient: PropTypes.shape({
      enabled: PropTypes.bool,
      type: PropTypes.string.isRequired,
      color1: PropTypes.string.isRequired,
      color2: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }).isRequired,
    textColor: PropTypes.string,
    animation: PropTypes.shape({
      backgroundSize: PropTypes.string.isRequired,
      transition: PropTypes.string.isRequired,
      animation: PropTypes.string,
    }).isRequired,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
        style: PropTypes.shape({
          backgroundColor: PropTypes.string,
          padding: PropTypes.string,
          borderRadius: PropTypes.string,
          border: PropTypes.string,
          fontSize: PropTypes.string,
          marginLeft: PropTypes.string,
          width: PropTypes.string,
        }),
      })
    ).isRequired,
  }).isRequired,
  onButtonSettingsChange: PropTypes.func.isRequired,
  onEnableChange: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
  avatarPosition: PropTypes.oneOf(["left", "right"]),
};

export default AdvancedButtonSettings;
