// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./papyrusEffect.module.css";

const PapyrusEffect = ({ text, options, isEnabled, position }) => {
  const [isVisible, setIsVisible] = useState(false);

  const isAvatarOnRight = position === "right";

  useEffect(() => {
    if (!isEnabled) return;

    const intervalId = setInterval(
      () => {
        setIsVisible((prevVisible) => !prevVisible);
      },
      isVisible ? 10000 : 3000
    );

    return () => clearInterval(intervalId);
  }, [isEnabled, isVisible]);

  if (!isEnabled) return null;

  const papyrusStyle = {
    backgroundColor: options.backgroundColor || "burlywood",
    color: options.textColor || "black",
    width: `${options.width || 300}px`,
    height: `${options.height || 60}px`,
    fontSize: `${options.fontSize || 20}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transformOrigin: isAvatarOnRight ? "right center" : "left center",
    animation: isVisible ? `${styles.unfoldPapyrus} 3s forwards` : `${styles.foldPapyrus} 3s forwards`,
    borderRadius: isAvatarOnRight ? "30px 0 0 30px" : "0 30px 30px 0",
  };

  const robotStyle = {
    width: "60px",
    height: "60px",
    position: "absolute",
    zIndex: 999,
    left: isAvatarOnRight ? `calc(100% - ${options.width + 20}px)` : `${options.width + 20}px`,
    animation: isVisible
      ? isAvatarOnRight
        ? `${styles.unfoldAndSlideLeft} 3s forwards`
        : `${styles.unfoldAndSlideRight} 3s forwards`
      : isAvatarOnRight
        ? `${styles.foldAndSlideBackLeft} 3s forwards`
        : `${styles.foldAndSlideBackRight} 3s forwards`,
  };

  return (
    <div className={styles.papyrusEffectContainer}>
      <div className={styles.papyrusEffect} style={papyrusStyle}>
        <span>{text}</span>
      </div>
      <img src="https://synthetic-bot-test.s3.eu-north-1.amazonaws.com/info.png" alt="Robot" style={robotStyle} />
    </div>
  );
};

PapyrusEffect.propTypes = {
  text: PropTypes.string.isRequired,
  options: PropTypes.shape({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.number,
  }).isRequired,
  isEnabled: PropTypes.bool,
  position: PropTypes.string.isRequired, // "right" или "left"
};

PapyrusEffect.defaultProps = {
  isEnabled: false,
};

export default PapyrusEffect;
