// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import PulsarSettings from "../create_synthetic/settings/advance_settings/sub_section/PulsarSettings";
import ClassicButtonSettings from "../create_synthetic/settings/advance_settings/sub_section/ClassicButtonSettings";
import { updateSyntheticStyle, getSyntheticStyleById } from "../userSyntheticSlice";
import PreLoading from "../../../components/pre_loading/PreLoading";
import CommonErrorWindow from "../../../components/popup_windows/common_error_window/CommonErrorWindow";
import styles from "./updateSyntheticStyle.module.css";
import buttonStyle from "../../../components/button/button.module.css";
import Button from "../../../components/button/Button";
import PapyrusNotificationSettings from "../create_synthetic/settings/advance_settings/sub_section/PapyrusNotificationSettings";
import AdvancedButtonSettings from "../create_synthetic/settings/advance_settings/sub_section/AdvancedButtonSettings";

const UpdateSyntheticStyle = ({ syntheticId, avatar, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [styleSettings, setStyleSettings] = useState(null);
  const [initialSettings, setInitialSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPulsarSettingsEnabled, setIsPulsarSettingsEnabled] = useState(false);
  const [isClassicButtonSettingsEnabled, setIsClassicButtonSettingsEnabled] = useState(false);
  const [isAdvancedButtonSettingsEnabled, setIsAdvancedButtonSettingsEnabled] = useState(false);
  const [isPapyrusSettingsEnabled, setIsPapyrusSettingsEnabled] = useState(false);

  const [position, setPosition] = useState("right");
  const [rightMargin, setRightMargin] = useState(null);
  const [bottomMargin, setBottomMargin] = useState(null);

  const [hasChanges, setHasChanges] = useState(false);

  const getFullSettings = (settings = {}) => ({
    left: settings.left ?? "none",
    right: settings.right ?? "none",
    bottom: settings.bottom ?? "5",
    isPulsarSettingsEnabled: settings.isPulsarSettingsEnabled ?? false,
    isClassicButtonSettingsEnabled: settings.isClassicButtonSettingsEnabled ?? false,
    isAdvancedButtonSettingsEnabled: settings.isAdvancedButtonSettingsEnabled ?? false,
    isPapyrusSettingsEnabled: settings.isPapyrusSettingsEnabled ?? true,
    pulsarColor: settings.pulsarColor || "#000000",
    pulsarSize: settings.pulsarSize || 10,
    pulsarIntensive: settings.pulsarIntensive || 1,
    classicButtonOptions: {
      count: settings.buttonOptions?.count ?? 1,
      text1: settings.buttonOptions?.text1 || "",
      text2: settings.buttonOptions?.text2 || "",
      colorBtn1: settings.buttonOptions?.colorBtn1 || "#000000",
      colorBtn2: settings.buttonOptions?.colorBtn2 || "#000000",
      colorTxt1: settings.buttonOptions?.colorTxt1 || "#ffffff",
      colorTxt2: settings.buttonOptions?.colorTxt2 || "#ffffff",
      fontSize: settings.buttonOptions?.fontSize || 14,
      url1: settings.buttonOptions?.url1 || "",
      url2: settings.buttonOptions?.url2 || "",
      displayCondition:
        settings.buttonOptions?.displayCondition === null ? 1 : settings.buttonOptions?.displayCondition ?? 1,
    },
    papyrusOptions: {
      text: settings.papyrusOptions?.text || "I can speak!!",
      textColor: settings.papyrusOptions?.textColor || "#000000",
      backgroundColor: settings.papyrusOptions?.backgroundColor || "#D2B48C",
      width: settings.papyrusOptions?.width ?? 300,
      height: settings.papyrusOptions?.height ?? 60,
      fontSize: settings.papyrusOptions?.fontSize || 20,
    },
    advancedButtonOptions: {
      gradient: {
        enabled: settings.advancedButtonOptions?.gradient?.enabled ?? false,
        type: settings.advancedButtonOptions?.gradient?.type || "linear",
        color1: settings.advancedButtonOptions?.gradient?.color1 || "#000000",
        color2: settings.advancedButtonOptions?.gradient?.color2 || "#ffffff",
        direction: settings.advancedButtonOptions?.gradient?.direction || "to right",
      },
      textColor: settings.advancedButtonOptions?.textColor || "#ffffff",
      animation: {
        backgroundSize: settings.advancedButtonOptions?.animation?.backgroundSize || "100% 100%",
        transition: settings.advancedButtonOptions?.animation?.transition || "none",
        animation: settings.advancedButtonOptions?.animation?.animation || undefined,
      },
      buttons: (settings.advancedButtonOptions?.buttons || []).map((button) => ({
        text: button?.text || "Button",
        action: button?.action || "#",
        style: {
          backgroundColor: button?.style?.backgroundColor || "#000000",
          padding: button?.style?.padding || "10px",
          borderRadius: button?.style?.borderRadius || "5px",
          border: button?.style?.border || "none",
          fontSize: button?.style?.fontSize || "14px",
          marginLeft: button?.style?.marginLeft || "0",
          width: button?.style?.width || "130px",
        },
      })),
    },
  });

  const handleMarginChange = (value, setter) => {
    if (value === "") {
      setter("");
    } else {
      const numericValue = parseFloat(value.replace("%", ""));
      if (!Number.isNaN(numericValue) && numericValue >= 0) {
        setter(numericValue);
      }
    }
  };

  useEffect(() => {
    const loadStyleSettings = () => {
      dispatch(getSyntheticStyleById(syntheticId))
        .unwrap()
        .then((result) => {
          const fullInitialSettings = getFullSettings(result);
          setInitialSettings(fullInitialSettings);

          setStyleSettings(result);

          const left = result.left ? result.left.replace("%", "") : "none";
          const right = result.right ? result.right.replace("%", "") : "3";
          const bottom = result.bottom ? result.bottom.replace("%", "") : "5";

          setPosition(result.left !== "none" ? "left" : "right");
          setRightMargin(left !== "none" ? left : right || "3");
          setBottomMargin(bottom);
          setIsPulsarSettingsEnabled(result.isPulsarSettingsEnabled);
          setIsClassicButtonSettingsEnabled(result.isClassicButtonSettingsEnabled);
          setIsPapyrusSettingsEnabled(result.isPapyrusSettingsEnabled);
          setIsAdvancedButtonSettingsEnabled(result.isAdvancedButtonSettingsEnabled);

          setIsLoading(false);
        })
        .catch((error) => {
          setIsError(true);
          setErrorMessage(error.response?.data?.message || error.message);
          setIsLoading(false);
        });
    };

    loadStyleSettings();
  }, [dispatch, syntheticId]);

  const checkForChanges = useCallback(() => {
    const currentSettings = getFullSettings({
      left: position === "left" ? rightMargin : "none",
      right: position === "right" ? rightMargin : "none",
      bottom: bottomMargin,
      isPulsarSettingsEnabled,
      isClassicButtonSettingsEnabled,
      isAdvancedButtonSettingsEnabled,
      isPapyrusSettingsEnabled,
      pulsarColor: styleSettings?.pulsarColor,
      pulsarSize: styleSettings?.pulsarSize,
      pulsarIntensive: styleSettings?.pulsarIntensive,
      classicButtonOptions: styleSettings?.buttonOptions,
      advancedButtonOptions: styleSettings?.advancedButtonOptions,
      papyrusOptions: styleSettings?.papyrusOptions,
    });

    setHasChanges(!_.isEqual(currentSettings, initialSettings));
  }, [
    position,
    rightMargin,
    bottomMargin,
    isPulsarSettingsEnabled,
    isClassicButtonSettingsEnabled,
    isAdvancedButtonSettingsEnabled,
    isPapyrusSettingsEnabled,
    styleSettings?.pulsarColor,
    styleSettings?.pulsarSize,
    styleSettings?.pulsarIntensive,
    styleSettings?.buttonOptions,
    styleSettings?.advancedButtonOptions,
    styleSettings?.papyrusOptions,
    initialSettings,
  ]);

  useEffect(() => {
    if (initialSettings) {
      checkForChanges();
    }
  }, [checkForChanges, initialSettings]);

  const handleSave = () => {
    const updatedSettings = {
      left: position === "left" ? rightMargin : "none",
      right: position === "right" ? rightMargin : "none",
      bottom: bottomMargin,
      isPulsarSettingsEnabled,
      isClassicButtonSettingsEnabled,
      isAdvancedButtonSettingsEnabled,
      isPapyrusSettingsEnabled,
      classicButtonOptions: styleSettings?.buttonOptions,
      advancedButtonOptions: styleSettings?.advancedButtonOptions,
      pulsarColor: styleSettings?.pulsarColor,
      pulsarSize: styleSettings?.pulsarSize,
      pulsarIntensive: styleSettings?.pulsarIntensive,
      papyrusOptions: styleSettings?.papyrusOptions,
    };

    dispatch(updateSyntheticStyle({ syntheticId, styleData: updatedSettings }))
      .unwrap()
      .then(() => {
        setHasChanges(false);
        setInitialSettings(updatedSettings);
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage(error.response?.data?.message || error.message);
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    if (hasChanges) {
      setPosition(initialSettings.position);
      setRightMargin(initialSettings.right);
      setBottomMargin(initialSettings.bottom);
      setIsPulsarSettingsEnabled(initialSettings.isPulsarSettingsEnabled);
      setIsClassicButtonSettingsEnabled(initialSettings.isClassicButtonSettingsEnabled);
      setIsAdvancedButtonSettingsEnabled(initialSettings.isAdvancedButtonSettingsEnabled);
      setIsPapyrusSettingsEnabled(initialSettings.isPapyrusSettingsEnabled);
    }
    navigate(-1);
  };

  if (isError) {
    return (
      <CommonErrorWindow open={isError} onClose={() => setIsError(false)} text={errorMessage} redirectToLogin={false} />
    );
  }

  if (isLoading) return <PreLoading />;

  return (
    <div className={styles.updateStyleContainer}>
      <h2>{t("updateSyntheticStyle") + name}</h2>

      <div className={styles.customizeContainer}>
        <div className={styles.leftSection}>
          <label>
            <input
              className={styles.inputPos}
              type="radio"
              name="position"
              value="left"
              checked={position === "left"}
              onChange={() => setPosition("left")}
            />
            Left position
          </label>
          <label>
            <input
              className={styles.inputPos}
              type="radio"
              name="position"
              value="right"
              checked={position === "right"}
              onChange={() => setPosition("right")}
            />
            Right position
          </label>

          <div className={styles.inputGroup}>
            <label>{position === "left" ? "Left margin (%)" : "Right margin (%)"}</label>
            <input
              type="text"
              value={`${rightMargin}`}
              onChange={(e) => handleMarginChange(e.target.value, setRightMargin)}
              placeholder={position === "left" ? "e.g., 10%" : "e.g., 10%"}
            />
          </div>

          <div className={styles.inputGroup}>
            <label>Bottom margin (%)</label>
            <input
              type="text"
              value={`${bottomMargin}`}
              onChange={(e) => handleMarginChange(e.target.value, setBottomMargin)}
              placeholder="e.g., 5%"
            />
          </div>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.userPageSimulation}>
            <div
              className={styles.videoBot}
              style={{
                position: "absolute",
                [position]: `${rightMargin}%`,
                bottom: `${bottomMargin}%`,
              }}
            >
              <div className={styles.botPlaceholder}>Synthetic position</div>
            </div>
          </div>
        </div>
      </div>

      <PulsarSettings
        selectedAvatar={`data:image/png;base64,${avatar}`}
        isEnabled={isPulsarSettingsEnabled}
        pulsarColor={styleSettings?.pulsarColor || "#000"}
        pulsarSize={styleSettings?.pulsarSize || 10}
        pulsarIntensive={styleSettings?.pulsarIntensive || false}
        onPulsarSettingsChange={(pulsarSettings) => {
          setStyleSettings((prevSettings) => ({
            ...prevSettings,
            pulsarColor: pulsarSettings.pulsarColor,
            pulsarSize: pulsarSettings.pulsarSize,
            pulsarIntensive: pulsarSettings.pulsarIntensive,
          }));
        }}
        onEnableChange={setIsPulsarSettingsEnabled}
      />

      <ClassicButtonSettings
        selectedAvatar={`data:image/png;base64,${avatar}`}
        classicButtonOptions={styleSettings?.buttonOptions || {}}
        isEnabled={isClassicButtonSettingsEnabled}
        onClassicButtonSettingsChange={(classicButtonOptions) => {
          setStyleSettings((prevSettings) => ({
            ...prevSettings,
            buttonOptions: classicButtonOptions,
          }));
        }}
        onEnableChange={setIsClassicButtonSettingsEnabled}
      />

      <AdvancedButtonSettings
        selectedAvatar={`data:image/png;base64,${avatar}`}
        advancedButtonOptions={styleSettings?.advancedButtonOptions || {}}
        isEnabled={isAdvancedButtonSettingsEnabled}
        onButtonSettingsChange={(advancedButtonOptions) => {
          setStyleSettings((prevSettings) => ({
            ...prevSettings,
            advancedButtonOptions,
          }));
        }}
        onEnableChange={setIsAdvancedButtonSettingsEnabled}
        position={position}
      />

      <PapyrusNotificationSettings
        selectedAvatar={`data:image/png;base64,${avatar}`}
        papyrusOptions={styleSettings?.papyrusOptions || {}}
        isEnabled={isPapyrusSettingsEnabled}
        onPapyrusSettingsChange={(papyrusOptions) => {
          setStyleSettings((prevSettings) => ({
            ...prevSettings,
            papyrusOptions,
          }));
        }}
        onEnableChange={setIsPapyrusSettingsEnabled}
        position={position}
      />

      <div className={styles.buttonContainer}>
        <Button
          buttonName={t("saveBtn")}
          buttonType="submit"
          onClick={handleSave}
          className={hasChanges ? buttonStyle.buttonSave : buttonStyle.buttonDisabled}
          disabled={!hasChanges}
        />
        <Button buttonName="Back" buttonType="button" onClick={handleCancel} className={buttonStyle.buttonBack} />
      </div>
    </div>
  );
};

UpdateSyntheticStyle.propTypes = {
  syntheticId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default UpdateSyntheticStyle;
