// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./classicButtonSettings.module.css";

const ClassicButtonSettings = ({
  selectedAvatar,
  classicButtonOptions,
  onClassicButtonSettingsChange,
  onEnableChange,
  isEnabled,
}) => {
  const [buttonEnabled, setButtonEnabled] = useState(isEnabled);

  useEffect(() => {
    setButtonEnabled(isEnabled);
  }, [isEnabled]);

  const handleOptionChange = (field, value) => {
    onClassicButtonSettingsChange({ ...classicButtonOptions, [field]: value });
  };

  const singleButtonStyle = {
    backgroundColor: classicButtonOptions.colorBtn1,
    color: classicButtonOptions.colorTxt1,
    padding: "3px",
    fontSize: `${classicButtonOptions.fontSize}px`,
    margin: "0 0 15px 0",
  };

  const startButtonStyle = {
    backgroundColor: classicButtonOptions.colorBtn1,
    color: classicButtonOptions.colorTxt1,
    padding: "3px 3px",
    fontSize: `${classicButtonOptions.fontSize}px`,
    margin: "0 3px 15px 0",
  };

  const stopButtonStyle = {
    backgroundColor: classicButtonOptions.colorBtn2,
    color: classicButtonOptions.colorTxt2,
    padding: "3px 3px",
    fontSize: `${classicButtonOptions.fontSize}px`,
    margin: "0 0 15px 3px",
  };

  useEffect(() => {
    onEnableChange(buttonEnabled);
  }, [buttonEnabled, onEnableChange]);

  return (
    <div className={styles.buttonSettingsContainer}>
      <div className={styles.leftSettings}>
        <h3>
          <u>Classic button options</u>
        </h3>

        <div className={styles.settingsRow}>
          <label className={styles.labelWithInput}>
            <input type="checkbox" checked={!!buttonEnabled} onChange={() => setButtonEnabled(!buttonEnabled)} />
            Enable
          </label>
        </div>
        {buttonEnabled && (
          <>
            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Display condition:
                <select
                  value={classicButtonOptions.displayCondition}
                  onChange={(e) => handleOptionChange("displayCondition", parseInt(e.target.value, 10))}
                >
                  <option value={1}>On start</option>
                  <option value={2}>On end</option>
                </select>
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Button count:
                <select
                  value={classicButtonOptions.count}
                  onChange={(e) => handleOptionChange("count", parseInt(e.target.value, 10))}
                >
                  <option value={1}>One button</option>
                  <option value={2}>Two buttons</option>
                </select>
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Button 1 color:
                <input
                  type="color"
                  value={classicButtonOptions.colorBtn1}
                  onChange={(e) => handleOptionChange("colorBtn1", e.target.value)}
                />
              </label>
            </div>

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Button 1 text:
                <input
                  type="text"
                  value={classicButtonOptions.text1}
                  onChange={(e) => handleOptionChange("text1", e.target.value)}
                />
              </label>
              <label className={styles.labelWithInput}>
                <input
                  type="color"
                  value={classicButtonOptions.colorTxt1}
                  onChange={(e) => handleOptionChange("colorTxt1", e.target.value)}
                />
              </label>
            </div>

            {classicButtonOptions.count === 2 && (
              <div className={styles.settingsRow}>
                <label className={styles.labelWithInput}>
                  Button 2 color:
                  <input
                    type="color"
                    value={classicButtonOptions.colorBtn2}
                    onChange={(e) => handleOptionChange("colorBtn2", e.target.value)}
                  />
                </label>
              </div>
            )}

            {classicButtonOptions.count === 2 && (
              <div className={styles.settingsRow}>
                <label className={styles.labelWithInput}>
                  Button 2 text:
                  <input
                    type="text"
                    value={classicButtonOptions.text2}
                    onChange={(e) => handleOptionChange("text2", e.target.value)}
                  />
                </label>
                <label>
                  <input
                    type="color"
                    value={classicButtonOptions.colorTxt2}
                    onChange={(e) => handleOptionChange("colorTxt2", e.target.value)}
                  />
                </label>
              </div>
            )}

            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Font size (px):
                <input
                  type="number"
                  value={classicButtonOptions.fontSize}
                  onChange={(e) => handleOptionChange("fontSize", parseInt(e.target.value, 10))}
                />
              </label>
            </div>
            <div className={styles.settingsRow}>
              <label className={styles.labelWithInput}>
                Button 1 URL:
                <input
                  type="url"
                  value={classicButtonOptions.url1}
                  onChange={(e) => handleOptionChange("url1", e.target.value)}
                />
              </label>
            </div>

            {classicButtonOptions.count === 2 && (
              <div className={styles.settingsRow}>
                <label className={styles.labelWithInput}>
                  Button 2 URL:
                  <input
                    type="url"
                    value={classicButtonOptions.url2}
                    onChange={(e) => handleOptionChange("url2", e.target.value)}
                  />
                </label>
              </div>
            )}
          </>
        )}
      </div>

      {buttonEnabled && (
        <div className={styles.rightAvatarSimulator}>
          <div className={styles.buttonPreview}>
            {classicButtonOptions.count === 1 ? (
              <button
                className={styles.singleButton}
                type="button"
                style={singleButtonStyle}
                onClick={() => window.open(classicButtonOptions.url1 || "https://example.com/more", "_blank")}
              >
                {classicButtonOptions.text1 || "More info"}
              </button>
            ) : (
              <>
                <button
                  className={styles.doubleButton}
                  type="button"
                  style={startButtonStyle}
                  onClick={() => window.open(classicButtonOptions.url1 || "https://example.com/start", "_blank")}
                >
                  {classicButtonOptions.text1 || "Gift"}
                </button>
                <button
                  className={styles.doubleButton}
                  type="button"
                  style={stopButtonStyle}
                  onClick={() => window.open(classicButtonOptions.url2 || "https://example.com/stop", "_blank")}
                >
                  {classicButtonOptions.text2 || "Support"}
                </button>
              </>
            )}
          </div>
          <div className={styles.simulatorContainer}>
            <img src={selectedAvatar} alt="Avatar" className={styles.simulatorAvatar} />
          </div>
        </div>
      )}
    </div>
  );
};

ClassicButtonSettings.defaultProps = {
  isEnabled: false,
};

ClassicButtonSettings.propTypes = {
  selectedAvatar: PropTypes.string.isRequired,
  classicButtonOptions: PropTypes.shape({
    count: PropTypes.number.isRequired,
    colorTxt1: PropTypes.string.isRequired,
    colorTxt2: PropTypes.string.isRequired,
    colorBtn1: PropTypes.string.isRequired,
    colorBtn2: PropTypes.string.isRequired,
    text1: PropTypes.string.isRequired,
    text2: PropTypes.string,
    fontSize: PropTypes.number,
    url1: PropTypes.string,
    url2: PropTypes.string,
    displayCondition: PropTypes.number,
  }).isRequired,
  onClassicButtonSettingsChange: PropTypes.func.isRequired,
  onEnableChange: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
};

export default ClassicButtonSettings;
