import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setOnVideo } from "../../synthetic/syntheticParamsSlice";
import styles from "./newsDetail.module.css";

export default function NewsDetail() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const newsItem = location.state || {};

  useEffect(() => {
    if (newsItem.botVideoUrl) {
      dispatch(setOnVideo({ videoPath: newsItem.botVideoUrl, question: false }));
    }
  }, [newsItem, dispatch]);

  return (
    <div className={styles.newsContainer}>
      <h1 className={styles.title}>{t(newsItem.title)}</h1>
      <p className={styles.date}>{newsItem.date}</p>
      {newsItem.contentType === "text" ? (
        <img src={newsItem.imageUrl} alt={newsItem.title} className={styles.image} />
      ) : (
        <video src={newsItem.videoUrl} className={styles.video} controls />
      )}

      <div className={styles.content}>
        <p>{t(newsItem.fullTextP1)}</p>
        <p>{t(newsItem.fullTextP2)}</p>
        <p>{t(newsItem.fullTextP3)}</p>
        {newsItem.fullTextP4 && <p>{t(newsItem.fullTextP4)}</p>}
        {newsItem.fullTextP5 && <p>{t(newsItem.fullTextP5)}</p>}
      </div>

      <button onClick={() => navigate(-1)} className={styles.backButton}>
        {t("Back")}
      </button>
    </div>
  );
}
