// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTranslation } from "react-i18next";
import styles from "../../createSynthetic.module.css";
import buttonStyles from "../../../../../components/button/button.module.css";
import PulsarSettings from "./sub_section/PulsarSettings";
import ClassicButtonSettings from "./sub_section/ClassicButtonSettings";
import Button from "../../../../../components/button/Button";
import { useDefaultSettings } from "../../../../../default_context/DefaultSettingsContext";
import PapyrusNotificationSettings from "./sub_section/PapyrusNotificationSettings.jsx";
import AdvancedButtonSettings from "./sub_section/AdvancedButtonSettings";

const AdvancedSettings = ({ selectedAvatar, onSaveSettings, avatarPosition }) => {
  const { t } = useTranslation();
  const [showDropdownAdvancedSettings, setShowDropdownAdvancedSettings] = useState(false);
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
  const [isWarningActive, setIsWarningActive] = useState(false);
  const [isClassicButtonSettingsEnabled, setIsClassicButtonSettingsEnabled] = useState(false);
  const [isPulsarSettingsEnabled, setIsPulsarSettingsEnabled] = useState(false);
  const [isPapyrusSettingsEnabled, setIsPapyrusSettingsEnabled] = useState(true);
  const [isAdvancedButtonSettingsEnabled, setIsAdvancedButtonSettingsEnabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const {
    pulsarColor: defaultPulsarColor,
    pulsarSize: defaultPulsarSize,
    pulsarIntensive: defaultPulsarIntensive,
    classicButtonOptions: defaultClassicButtonOptions,
    papyrusOptions: defaultPapyrusOptions,
    advancedButtonOptions: defaultAdvancedButtonOptions,
  } = useDefaultSettings();

  const [pulsarColor, setPulsarColor] = useState(defaultPulsarColor);
  const [pulsarSize, setPulsarSize] = useState(defaultPulsarSize);
  const [pulsarIntensive, setPulsarIntensive] = useState(defaultPulsarIntensive);
  const [classicButtonOptions, setClassicButtonOptions] = useState(defaultClassicButtonOptions);
  const [papyrusOptions, setPapyrusOptions] = useState(defaultPapyrusOptions);
  const [advancedButtonOptions, setAdvancedButtonOptions] = useState(defaultAdvancedButtonOptions);

  const handleCancel = () => {
    setPulsarColor(defaultPulsarColor);
    setPulsarSize(defaultPulsarSize);
    setPulsarIntensive(defaultPulsarIntensive);
    setClassicButtonOptions(defaultClassicButtonOptions);
    setIsWarningActive(false);
    setIsSaveSuccessful(false);
    setIsSaveDisabled(false);
    setIsClassicButtonSettingsEnabled(false);
    setIsPulsarSettingsEnabled(false);
    setIsPapyrusSettingsEnabled(true);
    setIsAdvancedButtonSettingsEnabled(false);
    setPapyrusOptions(defaultPapyrusOptions);
    setAdvancedButtonOptions(defaultAdvancedButtonOptions);
  };

  const saveCustomSettings = () => {
    const settings = {
      pulsarColor,
      pulsarSize,
      pulsarIntensive,
      classicButtonOptions,
      papyrusOptions,
      isButtonSettingsEnabled: isClassicButtonSettingsEnabled,
      isPulsarSettingsEnabled,
      isPapyrusSettingsEnabled,
      isAdvancedButtonSettingsEnabled,
      advancedButtonOptions,
    };

    onSaveSettings(settings);
    setIsSaveSuccessful(true);
    setIsWarningActive(false);
    setIsSaveDisabled(true);
  };

  useEffect(() => {
    if (isSaveSuccessful) {
      setIsSaveDisabled(true);
      return;
    }

    const isPulsarChanged =
      pulsarColor !== defaultPulsarColor ||
      pulsarSize !== defaultPulsarSize ||
      pulsarIntensive !== defaultPulsarIntensive;

    const isClassicButtonSettingsChanged =
      JSON.stringify(classicButtonOptions) !== JSON.stringify(defaultClassicButtonOptions);
    const isPapyrusSettingChanged = JSON.stringify(papyrusOptions) !== JSON.stringify(defaultPapyrusOptions);
    const isAdvancedButtonSettingsChanged =
      JSON.stringify(advancedButtonOptions) !== JSON.stringify(defaultAdvancedButtonOptions);

    const hasChanges =
      isPulsarChanged ||
      isClassicButtonSettingsChanged ||
      isPapyrusSettingChanged ||
      isAdvancedButtonSettingsChanged ||
      isClassicButtonSettingsEnabled !== false ||
      isPulsarSettingsEnabled !== false ||
      isPapyrusSettingsEnabled !== true ||
      isAdvancedButtonSettingsEnabled !== false;

    setIsWarningActive(hasChanges);
    setIsSaveDisabled(!hasChanges);
  }, [
    pulsarColor,
    pulsarSize,
    pulsarIntensive,
    classicButtonOptions,
    papyrusOptions,
    advancedButtonOptions,
    isClassicButtonSettingsEnabled,
    isPulsarSettingsEnabled,
    isPapyrusSettingsEnabled,
    isAdvancedButtonSettingsEnabled,
    defaultPulsarColor,
    defaultPulsarSize,
    defaultPulsarIntensive,
    defaultClassicButtonOptions,
    defaultPapyrusOptions,
    defaultAdvancedButtonOptions,
    isSaveSuccessful,
  ]);

  const handlePulsarSettingsChange = (settings) => {
    setIsSaveSuccessful(false);
    setPulsarColor(settings.pulsarColor || defaultPulsarColor);
    setPulsarSize(settings.pulsarSize || defaultPulsarSize);
    setPulsarIntensive(settings.pulsarIntensive || defaultPulsarIntensive);
  };

  const handleButtonSettingsChange = (settings) => {
    setIsSaveSuccessful(false);
    setClassicButtonOptions(settings || defaultClassicButtonOptions);
  };

  const handlePapyrusSettingsChange = (settings) => {
    setIsSaveSuccessful(false);
    setPapyrusOptions(settings || defaultPapyrusOptions);
  };

  const handleAdvancedButtonSettingsChange = (settings) => {
    setIsSaveSuccessful(false);
    setAdvancedButtonOptions(settings || defaultAdvancedButtonOptions);
  };

  return (
    <div>
      <button
        className={styles.btnDropdown}
        id="btnDropdownAdvancedSettings"
        type="button"
        onClick={() => setShowDropdownAdvancedSettings(!showDropdownAdvancedSettings)}
      >
        <span className={styles.textIconInButton}>
          {isSaveSuccessful ? (
            <DoneIcon style={{ color: "green" }} />
          ) : isWarningActive ? (
            <WarningAmberIcon style={{ color: "#EC4A0A" }} />
          ) : null}
          6. Advanced settings{" "}
        </span>
        {showDropdownAdvancedSettings ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      {showDropdownAdvancedSettings && (
        <div className={styles.advancedElement}>
          <PulsarSettings
            selectedAvatar={selectedAvatar}
            pulsarColor={pulsarColor}
            pulsarSize={pulsarSize}
            pulsarIntensive={pulsarIntensive}
            onPulsarSettingsChange={handlePulsarSettingsChange}
            onEnableChange={setIsPulsarSettingsEnabled}
            isEnabled={isPulsarSettingsEnabled}
          />
          <ClassicButtonSettings
            selectedAvatar={selectedAvatar}
            classicButtonOptions={classicButtonOptions}
            onClassicButtonSettingsChange={handleButtonSettingsChange}
            onEnableChange={setIsClassicButtonSettingsEnabled}
            isEnabled={isClassicButtonSettingsEnabled}
          />

          <AdvancedButtonSettings
            selectedAvatar={selectedAvatar}
            advancedButtonOptions={advancedButtonOptions}
            isEnabled={isAdvancedButtonSettingsEnabled}
            onButtonSettingsChange={handleAdvancedButtonSettingsChange}
            onEnableChange={setIsAdvancedButtonSettingsEnabled}
            position={avatarPosition}
          />

          <PapyrusNotificationSettings
            selectedAvatar={selectedAvatar}
            papyrusOptions={papyrusOptions}
            onPapyrusSettingsChange={handlePapyrusSettingsChange}
            onEnableChange={setIsPapyrusSettingsEnabled}
            isEnabled={isPapyrusSettingsEnabled}
            position={avatarPosition}
          />

          {(isClassicButtonSettingsEnabled ||
            isPulsarSettingsEnabled ||
            isPapyrusSettingsEnabled ||
            isAdvancedButtonSettingsEnabled) && (
            <div className={styles.buttonLineElement}>
              <Button
                buttonName={t("saveBtn")}
                buttonType="submit"
                onClick={saveCustomSettings}
                className={`${buttonStyles.buttonSave}`}
                disabled={isSaveDisabled}
              />
              <Button
                buttonName={t("cancelBtn")}
                buttonType="button"
                onClick={handleCancel}
                className={buttonStyles.buttonCancel}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AdvancedSettings.propTypes = {
  selectedAvatar: PropTypes.string.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
  avatarPosition: PropTypes.string.isRequired,
};

export default AdvancedSettings;
