import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import DownloadIcon from "@mui/icons-material/Download";
import VerifiedIcon from "@mui/icons-material/Verified";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { selectAllSynthetic, selectIsLoadingUpdateTask } from "../../selectors.js";
import {
  loadAllSynthetic,
  loadConfigData,
  loadTask,
  updateTask,
  deleteSynthetic,
  closeErrorWindow,
} from "../../userSyntheticSlice.js";
import styles from "./detailsSynthetic.module.css";
import { loadAuthUser } from "../../../auth/authSlice.js";
import CommonErrorWindow from "../../../../components/popup_windows/common_error_window/CommonErrorWindow";
import Button from "../../../../components/button/Button";
import buttonStyle from "../../../../components/button/button.module.css";
import ConfirmDeleteSynWindow from "../../../../components/popup_windows/confirm_delete_syn_window/ConfirmDeleteSynWindow";

export default function DetailsSynthetic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoadingUpdateTask = useSelector(selectIsLoadingUpdateTask);
  const synthetics = useSelector(selectAllSynthetic);

  const showErrorWindow = useSelector((state) => state.userSynthetic.showErrorWindow);
  const errorWindowMessage = useSelector((state) => state.userSynthetic.errorWindowMessage);

  const { synId } = useParams();
  const [synthetic, setSynthetic] = useState();
  const [hoveredButton, setHoveredButton] = useState(null);
  const [activeButton, setActiveButton] = useState("button1");
  const [configData, setConfigData] = useState(undefined);
  const [task, setTask] = useState(undefined);
  const [isCopiedKey, setIsCopiedKey] = useState(false);
  const [videoRun, setVideoRun] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const [deleteWin, setDeleteWin] = useState(false);
  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleClick = (path, buttonId) => {
    navigate(path);
    setActiveButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const updateStatus = () => {
    dispatch(updateTask(synthetic.syntheticUuid)).then((taskData) => {
      setTask(taskData.payload);
      dispatch(loadAuthUser());
    });
  };

  const deleteSyn = async () => {
    await dispatch(deleteSynthetic(synId)).then(async () => {
      await dispatch(loadAllSynthetic());
      navigate("/account/mySynthetic");
    });
  };

  // const handleEdit = async () => {
  //   navigate(`/account/mySynthetic/edit/${synId}`);
  // };

  useEffect(() => {
    dispatch(loadAllSynthetic());
  }, [dispatch]);

  useEffect(() => {
    if (synthetics.length > 0) {
      const numberSynId = synId;
      setSynthetic(synthetics.find((syn) => syn.syntheticUuid === numberSynId));
    }
  }, [synthetics, synId]);

  useEffect(() => {
    if (synthetic) {
      dispatch(loadConfigData(synthetic.configDataId)).then((data) => {
        setConfigData(data.payload);
      });
    }
  }, [dispatch, synthetic]);

  useEffect(() => {
    if (configData && configData.id) {
      dispatch(loadTask(configData.taskId)).then((taskData) => {
        setTask(taskData.payload);
      });
    }
  }, [dispatch, configData]);

  const handleCopyKey = () => {
    if (synthetic && synthetic.syntheticUuid) {
      navigator.clipboard
        .writeText(synthetic.syntheticUuid)
        .then(() => {
          setIsCopiedKey(true);
          setTimeout(() => setIsCopiedKey(false), 2000);
        })
        .catch((err) => {
          console.error("Copy error: ", err);
        });
    }
  };

  const handleCopyScript = () => {
    navigator.clipboard
      .writeText(
        `<script src="https://synthetic-bot.s3.eu-north-1.amazonaws.com/synthetic.min.js" data-param="KEY-SYN"></script>`
      )
      .then(() => {})
      .catch((err) => {
        console.error("Copy error: ", err);
      });
  };

  function handleDownload(fileName) {
    const link = document.createElement("a");
    link.href = `https://synthetic-bot.s3.eu-north-1.amazonaws.com/${fileName}`;
    link.setAttribute("download", "");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleOnClick = () => {
    setVideoRun(true);
    setAutoPlay(true);
  };

  function pad(number) {
    return number < 10 ? `0${number}` : number;
  }

  function formatBeautifully(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${day}.${month}.${year}  ${hours}:${minutes}`;
  }

  const handleVideoEnded = () => {
    setAutoPlay(false);
    setVideoRun(false);
  };

  const handleCloseErrorWindow = () => {
    dispatch(closeErrorWindow());
  };
  const handleCancelDelete = () => {
    setDeleteWin(!deleteWin);
  };

  return (
    <>
      {showErrorWindow && <CommonErrorWindow text={errorWindowMessage} onClose={handleCloseErrorWindow} open />}

      {synthetic && (
        <div className={styles.mainBox}>
          <div className={styles.leftBarContainer}>
            <div className={styles.backContainer}>
              <img src="/circle.webp" alt="buttonBack" />
            </div>
            <div>
              <button
                className={`${styles.buttonBack} ${activeButton === "buttonBack" ? styles.buttonBackPressed : ""}`}
                type="button"
                onClick={() => handleClick("/account/mySynthetic", "buttonBack")}
                id="backToMySyntheticButton"
              >
                <div className={styles.buttonBackContainer}>
                  <img src="/arrow-right.webp" alt="arrow" className={styles.arrowIcon} />
                  <div className={styles.buttonBackTextContainer}>
                    <span>My Synthetic</span>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button
                className={`${styles.button1} ${activeButton === "button1" || hoveredButton === "button1" ? styles.buttonPressed : ""}`}
                type="button"
                onClick={() => handleClick(`/account/mySynthetic/${synId}`, "button1")}
                onMouseEnter={() => handleMouseEnter("button1")}
                onMouseLeave={handleMouseLeave}
                id="generalButton"
              >
                <div className={styles.buttonContainer}>
                  <div className={styles.textContainer}>
                    <span>General</span>
                  </div>
                  {activeButton === "button1" && (
                    <div className={styles.imageContainer}>
                      <img src="/circle.webp" alt="button1" />
                    </div>
                  )}
                </div>
              </button>
              <button
                className={`${styles.button2} ${activeButton === "button2" || hoveredButton === "button2" ? styles.buttonPressed : ""}`}
                type="button"
                onClick={() => handleClick(`/account/mySynthetic/editStyle/${synId}`, "button2")}
                onMouseEnter={() => handleMouseEnter("button2")}
                onMouseLeave={handleMouseLeave}
                id="editStyleButton"
              >
                <div className={styles.buttonContainer}>
                  <div className={styles.textContainer}>
                    <span>Edit style</span>
                  </div>
                  {hoveredButton === "button2" && (
                    <div className={styles.imageContainer}>
                      <img src="/circle.webp" alt="button2" />
                    </div>
                  )}
                </div>
              </button>
              <button
                className={`${styles.button3} ${activeButton === "button3" || hoveredButton === "button3" ? styles.buttonPressed : ""}`}
                type="button"
                onClick={() => handleClick(`/account/mySynthetic/metrics/${synId}`, "button3")}
                onMouseEnter={() => handleMouseEnter("button3")}
                onMouseLeave={handleMouseLeave}
                id="metricsDataButton"
              >
                <div className={styles.buttonContainer}>
                  <div className={styles.textContainer}>
                    <span>Statistics</span>
                  </div>
                  {hoveredButton === "button3" && (
                    <div className={styles.imageContainer}>
                      <img src="/circle.webp" alt="button3" />
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
          <div className={styles.mainContainer}>
            <div className={styles.createdContainer}>
              <p className={styles.createdLabel}>
                Date of creation: <span className={styles.createdAt}>{formatBeautifully(synthetic.createdAt)}</span>
              </p>
            </div>
            <div className={styles.nameContainer}>
              <p className={styles.name}>{synthetic.name}</p>
            </div>
            <div className={styles.avatarBox}>
              <div className={styles.avatarNameContainer}>
                {videoRun ? (
                  <div>
                    <video
                      src={task.urlToVideo}
                      preload="metadata"
                      playsInline
                      poster="/Dual-Ring.png"
                      autoPlay={autoPlay}
                      className={styles.avatar}
                      onEnded={handleVideoEnded}
                    >
                      <span>My Synthetic</span>
                    </video>
                  </div>
                ) : (
                  <button type="button" className={styles.avatarContainer} onClick={handleOnClick}>
                    <img
                      className={styles.avatar}
                      src={`data:image/*;base64,${synthetic.avatar}`}
                      alt={synthetic.name}
                    />
                  </button>
                )}
              </div>
              {deleteWin && <ConfirmDeleteSynWindow handleConfirm={deleteSyn} handleCancel={handleCancelDelete} />}
              {task && task.taskState !== "SUCCESS" && (
                <button
                  className={styles.updateStatusBtn}
                  id="updateStatus"
                  type="button"
                  onClick={updateStatus}
                >
                  Update status
                </button>
              )}
              {task && task.taskState === "SUCCESS" && (
                <div className={styles.twoBtns}>
                  <button className={styles.deleteBtn} id="deleteBtn" type="button" onClick={() => setDeleteWin(true)}>
                    Delete
                  </button>

                  {/* <Button */}
                  {/*  buttonName="Edit speech" */}
                  {/*  buttonType="button" */}
                  {/*  onClick={handleEdit} */}
                  {/*  className={`${buttonStyle.buttonGlobal} ${styles.buttonEdit}`} */}
                  {/* /> */}
                </div>
              )}
              <div className={styles.statusContainer}>
                <div className={styles.statusId}>
                  <div className={styles.status}>
                    <span className={styles.statusHeader}>STATUS: </span>
                  </div>
                  <div className={styles.idContainer}>
                    {!task || isLoadingUpdateTask ? (
                      <div className={styles.loaderIcon} />
                    ) : (
                      <span>{task.taskState}</span>
                    )}
                    {task && task.taskState === "SUCCESS" && <VerifiedIcon />}
                  </div>
                </div>
              </div>
            </div>
            {task && task.taskState === "SUCCESS" && (
              <div className={styles.keyContainer}>
                <div className={styles.key}>
                  <span className={styles.keyHeader}>KEY-SYN:</span>
                  <span className={styles.keyValue}>{synthetic.syntheticUuid}</span>
                  <div className={styles.copyContainer}>
                    <button type="button" className={styles.copyButton} onClick={handleCopyKey} aria-label="Copy key">
                      <ContentCopyIcon className={styles.iconCopy} />
                    </button>
                    {isCopiedKey && <span className={styles.copiedNotification}>Key copied!</span>}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.instructionBox}>
            <h3 className={styles.headerInstruction}>
              <IntegrationInstructionsIcon />
              <span>Helpful information</span>
            </h3>
            <h3 className={styles.headerInstruction}>
              <span>For clients with access to html pages</span>
            </h3>
            <div className={styles.scriptBoxInfo}>
              <span>Script for index.html:</span>
              <button type="button" onClick={handleCopyScript}>
                Copy script
                <ContentCopyIcon />
              </button>
            </div>
            <div className={styles.scriptBoxCode}>
              <pre className={styles.codeHtml}>
                &lt;script src=&quot;https://synthetic-bot.s3.eu-north-1.amazonaws.com/synthetic.min.js&quot;
                data-param=&quot;KEY-SYN&quot; &gt;&lt;/script&gt;
              </pre>
            </div>
            <div className={styles.libBoxDownload}>
              <span>Helpful guide:</span>
              <button type="button" onClick={() => handleDownload("guide_html.pdf")}>
                Download pdf
                <DownloadIcon />
              </button>
            </div>
            <h3 className={styles.headerInstruction}>
              <span>For WordPress clients</span>
            </h3>
            <div className={styles.libBoxDownload}>
              <span>Synthetic zip file:</span>
              <button type="button" onClick={() => handleDownload("video-bot-plugin.zip")}>
                Plugin file
                <DownloadIcon />
              </button>
            </div>
            <div className={styles.libBoxDownload}>
              <span>Helpful guide:</span>
              <button type="button" onClick={() => handleDownload("guide_wordpress.pdf")}>
                Download pdf
                <DownloadIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
